import React, { useEffect, useState } from 'react';
import proStyle from '../ahs-pro.module.css';
import { IconCancel } from '@ftdr/blueprint-components-react';
import { addYears } from 'date-fns';
import { useTealium } from '../../hooks';
import Cookies from 'js-cookie';

const CookieConsent = () => {
    const { trackEvent, trackView } = useTealium();

    const [hasConsented, setHasConsented]: any = useState(null);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const sessionConsent = sessionStorage.getItem('cookieConsent');
        const consent = Cookies.get('cookie_consent_bar_displayed');

        if (consent !== 'true') {
            sessionStorage.removeItem('cookieConsent');
        }

        if (consent == 'true' || !!sessionConsent) {
            setVisible(false);
        } else {
            trackView(
                {
                    data_id: 'cookie_consent_banner_view',
                    event_name: 'PV_cookie_consent_banner',
                    event_category: 'Cookie Consent',
                    event_action: 'View',
                    event_label: 'View Banner',
                },
                true,
            );
            setVisible(true);
            sessionStorage.setItem('cookieConsent', 'true');
        }

        // Store the cookie consent in a cookie of all things.
        // It should expire in 1 year.
        const exp = addYears(new Date(), 1);
        Cookies.set('cookie_consent_bar_displayed', 'true', {
            domain: '.frontdoor.com',
            expires: exp,
        });
    }, []);

    const closeMessage = () => {
        setVisible(false);
        setHasConsented('true');
        trackEvent(
            {
                data_id: 'cookie_consent_close_click',
                event_name: 'CLK_cookie_consent_close',
                event_category: 'Cookie Consent',
                event_action: 'Click',
                event_label: 'Close Banner',
            },
            true,
        );
    };

    return (
        <div
            id="cookie-consent-message"
            className={`${
                visible && !hasConsented ? 'flex' : 'hidden'
            } flex-col cookie-consent-panel p-4 z-10 w3-animate-bottom`}
        >
            <div className="flex w-full justify-between items-center">
                <span className="font-bold text-lg pl-3">
                    We value your privacy
                </span>
                <IconCancel
                    color="gray"
                    className="cursor-pointer"
                    onClick={closeMessage}
                />
            </div>
            <p className="p-3">
                We use cookies and other technologies for tracking, analytics
                and personalizing the ads or content you see. By using this
                website, you consent to our use of these technologies. For more
                information, please visit our{' '}
                <a
                    className="underline"
                    href="https://www.frontdoor.com/legal/privacy-policy"
                    target="_blank"
                    onClick={() => {
                        trackEvent(
                            {
                                data_id: 'cookie_consent_privacy_policy_click',
                                event_name: 'CLK_cookie_consent_privacy_policy',
                                event_category: 'Cookie Consent',
                                event_action: 'Click',
                                event_label: 'Privacy Policy',
                            },
                            true,
                        );
                    }}
                    rel="noreferrer"
                >
                    Privacy Policy
                </a>
                .
            </p>
        </div>
    );
};

export default CookieConsent;
